$base-color: #F15B2A;
$sub-color: #527efb;
$black: #363636;
$green: #4CAF50;
$gray: #9b9b9b;
$grayLight: #bfbfbf;
$grayLighter: #e5e5e5;
$invalid-color: #f86c6b;
$darkBlue: #3c4f5d;
$red: #ff0000;
$white: #FFFFFF;

$font-lg: 22px;
$font-xxl: 20px;
$font-xl: 18px;
$font-mdl: 16px;
$font-md: 14px;
$font-sm: 11px;
$screen-md: 576px;
$margin-xl: 24px;
$margin-mdl: 20px;
$margin-mdll: 30px;
$margin-md: 15px;
$margin-sm: 10px;
$margin-ssm: 5px;

@font-face {
  font-family: 'Prompt';
  src: url('../fonts/Prompt-Regular.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('../fonts/Prompt-Bold.ttf')  format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt-Light';
  src: url('../fonts/Prompt-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('../fonts/Prompt-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
 
@font-face {
  font-family: 'Prompt-Thin';
  src: url('../fonts/Prompt-Thin.ttf')  format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Prompt-SemiBold';
  src: url('../fonts/Prompt-SemiBold.ttf')  format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Prompt-Italic';
  src: url('../fonts/Prompt-Italic.ttf')  format('truetype');
  font-style: normal;
}
a { color: $base-color }

.tsm { font-size: $font-sm; }
.tmdl { font-size: $font-mdl; }
.tmd { font-size: $font-md; }
.txl { font-size: $font-xl; }
.txxl { font-size: $font-xxl; }
.tlg { font-size: $font-lg; }
.tlgbs {
    font-size: $font-lg;
    color: $base-color;
}

.msmt {
    margin-top: $margin-sm;
}

.pssm { padding: $margin-ssm; }
.pssmt { padding-top: $margin-ssm; }
.pssmb { padding-bottom: $margin-ssm; }
.pssml { padding-left: $margin-ssm; }
.pssmr { padding-right: $margin-ssm; }

.psm { padding: $margin-sm; }
.psmt { padding-top: $margin-sm; }
.psmb { padding-bottom: $margin-sm; }
.psml { padding-left: $margin-sm; }
.psmr { padding-right: $margin-sm; }

.pmd { padding: $margin-md; }
.pmdt { padding-top: $margin-md; }
.pmdb { padding-bottom: $margin-md; }
.pmdl { padding-left: $margin-md; }
.pmdr { padding-right: $margin-md; }
.pmdV { padding-top: $margin-md; padding-bottom: $margin-md; }
.pmdH { padding-left: $margin-md; padding-right: $margin-md; } 

.pmdla { padding: $margin-mdl; }
.pmdlt { padding-top: $margin-mdl; }
.pmdlb { padding-bottom: $margin-mdl; }
.pmdllb { padding-bottom: $margin-mdll; }
.pmdll { padding-left: $margin-mdl; }
.pmdlll { padding-left: $margin-mdll; }
.pmdlr { padding-right: $margin-mdl; }
.pmdlV { padding-top: $margin-mdl; padding-bottom: $margin-mdl; }
.pmdlH { padding-left: $margin-mdl; padding-right: $margin-mdl; } 

.pxl { padding: $margin-xl; }
.pxlt { padding-top: $margin-xl; }
.pxlb { padding-bottom: $margin-xl; }
.pxll { padding-left: $margin-xl; }
.pxlr { padding-right: $margin-xl; }
.pxlV { padding-top: $margin-xl; padding-bottom: $margin-xl; }
.pxlH { padding-left: $margin-xl; padding-right: $margin-xl; } 

.pxlV { padding-top: $margin-xl; padding-bottom: $margin-xl; }
.pxlH { padding-left: $margin-xl; padding-right: $margin-xl; }

.mmd { margin: $margin-md; }
.mmdt { margin-top: $margin-md; }
.mmdb { margin-bottom: $margin-md; }
.mmdllb { margin-bottom: $margin-mdll; }
.mmdl { margin-left: $margin-md; }
.mmdr { margin-right: $margin-md; }
.mmdV { margin-top: $margin-md; margin-bottom: $margin-md; }
.mmdH { margin-left: $margin-md; margin-right: $margin-md; } 

.mxlt { margin-top: $margin-xl; }
.mxlb { margin-bottom: $margin-xl; }
.mxll { margin-left: $margin-xl; }
.mxlr { margin-right: $margin-xl; }
.mxlV { margin-top: $margin-xl; margin-bottom: $margin-xl; }
.mxlH { margin-left: $margin-xl; margin-right: $margin-xl; } 


body {
  font-size: $font-md;
  font-family: Prompt !important;
  color: $black;
  background-color: #FAFAFA;
}

h3 { font-family: Prompt-SemiBold; }

.light { font-family: Prompt-Light; }
.thin { font-family: Prompt-Thin; }
.bold { font-weight: 600 !important; } 
.semiBold { font-family: Prompt-SemiBold; }
.medium { font-family: Prompt-Medium; }
.italic { font-family: Prompt-Italic; }

.underline { text-decoration: underline; }
.cursor {
  cursor: pointer;
}
.clear {
    clear: both;
}
 
.cbs { color: $base-color; }
.cs { color: $sub-color; } 
.cgr { color: $green; } 
.cg { color: $gray; } 
.cgl { color: $grayLight; }
.cglt { color: $grayLighter; } 
.cdb { color: $darkBlue; } 
.cb { color: $black; }
.cr { color: $red; }

.pull-left { float: left; }
.pull-right { float: right; }

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #CCCCCC !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #CCCCCC !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #CCCCCC !important;
}

.pdn { padding: 0 }

.pLeft { float: left; }
.pRight { float: right; }
.center { text-align: center; }
.left { text-align: left; }
.right { text-align: right;}

.autoHeight {
    min-height: 100%;
    height: 100%;
}

#container_app {
    position: relative;
}

.logo {
    width: 100%;
    padding: 20px;
}

.css-cveggr-MuiListItemIcon-root {
    min-width: 40px !important;
}

#nprogress .bar {
  z-index: 9999 !important;
  height: 5px !important;
  background-color: $base-color !important;
}

.hide {
    display: none !important;
}

.loading {
  text-align: center;
  left: 0;
  right: 0;
  position: absolute; 
}

.text-center {
  text-align: center;
}
