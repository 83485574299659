@font-face {
  font-family: "Prompt";
  src: url("../fonts/Prompt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Prompt-Bold";
  src: url("../fonts/Prompt-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Prompt-Light";
  src: url("../fonts/Prompt-Light.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Prompt-Medium";
  src: url("../fonts/Prompt-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Prompt-Thin";
  src: url("../fonts/Prompt-Thin.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Prompt-SemiBold";
  src: url("../fonts/Prompt-SemiBold.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Prompt-Italic";
  src: url("../fonts/Prompt-Italic.ttf") format("truetype");
  font-style: normal;
}
a {
  color: #f15b2a;
}

.tsm {
  font-size: 11px;
}

.tmdl {
  font-size: 16px;
}

.tmd {
  font-size: 14px;
}

.txl {
  font-size: 18px;
}

.txxl {
  font-size: 20px;
}

.tlg {
  font-size: 22px;
}

.tlgbs {
  font-size: 22px;
  color: #f15b2a;
}

.msmt {
  margin-top: 10px;
}

.pssm {
  padding: 5px;
}

.pssmt {
  padding-top: 5px;
}

.pssmb {
  padding-bottom: 5px;
}

.pssml {
  padding-left: 5px;
}

.pssmr {
  padding-right: 5px;
}

.psm {
  padding: 10px;
}

.psmt {
  padding-top: 10px;
}

.psmb {
  padding-bottom: 10px;
}

.psml {
  padding-left: 10px;
}

.psmr {
  padding-right: 10px;
}

.pmd {
  padding: 15px;
}

.pmdt {
  padding-top: 15px;
}

.pmdb {
  padding-bottom: 15px;
}

.pmdl {
  padding-left: 15px;
}

.pmdr {
  padding-right: 15px;
}

.pmdV {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pmdH {
  padding-left: 15px;
  padding-right: 15px;
}

.pmdla {
  padding: 20px;
}

.pmdlt {
  padding-top: 20px;
}

.pmdlb {
  padding-bottom: 20px;
}

.pmdllb {
  padding-bottom: 30px;
}

.pmdll {
  padding-left: 20px;
}

.pmdlll {
  padding-left: 30px;
}

.pmdlr {
  padding-right: 20px;
}

.pmdlV {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pmdlH {
  padding-left: 20px;
  padding-right: 20px;
}

.pxl {
  padding: 24px;
}

.pxlt {
  padding-top: 24px;
}

.pxlb {
  padding-bottom: 24px;
}

.pxll {
  padding-left: 24px;
}

.pxlr {
  padding-right: 24px;
}

.pxlV {
  padding-top: 24px;
  padding-bottom: 24px;
}

.pxlH {
  padding-left: 24px;
  padding-right: 24px;
}

.pxlV {
  padding-top: 24px;
  padding-bottom: 24px;
}

.pxlH {
  padding-left: 24px;
  padding-right: 24px;
}

.mmd {
  margin: 15px;
}

.mmdt {
  margin-top: 15px;
}

.mmdb {
  margin-bottom: 15px;
}

.mmdllb {
  margin-bottom: 30px;
}

.mmdl {
  margin-left: 15px;
}

.mmdr {
  margin-right: 15px;
}

.mmdV {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mmdH {
  margin-left: 15px;
  margin-right: 15px;
}

.mxlt {
  margin-top: 24px;
}

.mxlb {
  margin-bottom: 24px;
}

.mxll {
  margin-left: 24px;
}

.mxlr {
  margin-right: 24px;
}

.mxlV {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mxlH {
  margin-left: 24px;
  margin-right: 24px;
}

body {
  font-size: 14px;
  font-family: Prompt !important;
  color: #363636;
  background-color: #fafafa;
}

h3 {
  font-family: Prompt-SemiBold;
}

.light {
  font-family: Prompt-Light;
}

.thin {
  font-family: Prompt-Thin;
}

.bold {
  font-weight: 600 !important;
}

.semiBold {
  font-family: Prompt-SemiBold;
}

.medium {
  font-family: Prompt-Medium;
}

.italic {
  font-family: Prompt-Italic;
}

.underline {
  text-decoration: underline;
}

.cursor {
  cursor: pointer;
}

.clear {
  clear: both;
}

.cbs {
  color: #f15b2a;
}

.cs {
  color: #527efb;
}

.cgr {
  color: #4caf50;
}

.cg {
  color: #9b9b9b;
}

.cgl {
  color: #bfbfbf;
}

.cglt {
  color: #e5e5e5;
}

.cdb {
  color: #3c4f5d;
}

.cb {
  color: #363636;
}

.cr {
  color: #ff0000;
}

.corg {
  color: #ffae00;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cccccc !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cccccc !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cccccc !important;
}

.pdn {
  padding: 0;
}

.pLeft {
  float: left;
}

.pRight {
  float: right;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.autoHeight {
  min-height: 100%;
  height: 100%;
}

#container_app {
  position: relative;
}

.logo {
  width: 100%;
  padding: 20px;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 40px !important;
}

#nprogress .bar {
  z-index: 9999 !important;
  height: 5px !important;
  background-color: #f15b2a !important;
}

.hide {
  display: none !important;
}

.loading {
  text-align: center;
  left: 0;
  right: 0;
  position: absolute;
}

.text-center {
  text-align: center;
} 
